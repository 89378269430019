import { Directive, HostListener, Input } from "@angular/core";
import { Throttle } from "../../decorators/debounce.decorator";
import { S25Util } from "../../util/s25-util";

@Directive({ selector: "[s25-ng-auto-scroll]" })
export class S25AutoScrollDirective {
    @Input() step: number = 10;
    @Input() threshold: number = 0.25;

    @HostListener("dragover", ["$event"])
    @Throttle(10)
    dragOver(event: any) {
        const container = S25Util.getScrollableContainer(event.target);
        const offset = S25Util.clamp(this.getRelativeY(container, event), 0.01, 1);
        if (offset > this.threshold && offset < 1 - this.threshold) return; // Don't do anything if between thresholds
        const direction = offset <= this.threshold ? -1 : 1;
        const rel = (direction < 0 ? offset : 1 - offset) / this.threshold;
        container.scrollTop += direction * this.step * this.scale(rel);
    }

    scale(val: number) {
        return (1 - val) / val + 1;
    }

    getRelativeY(container: HTMLElement, event: any) {
        const rect = container.getBoundingClientRect();
        const y = event.clientY - rect.top;
        return y / rect.height;
    }
}
