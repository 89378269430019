import { Api } from "../../api/api";
import { S25DatepickerComponent } from "./s25.datepicker.component";
import { S25Util } from "../../util/s25-util";

export class DatepickerApi extends Api {
    public static refresh(relativeElem: HTMLElement, newDate?: any) {
        return Api.callApiFn(relativeElem, "s25-datepicker", null, null, (comp: S25DatepickerComponent) => {
            if (newDate && S25Util.date.isValid(newDate)) {
                if (comp && comp.modelValue) {
                    comp.modelValue.date = newDate;
                }
                comp && comp.ngOnInit();
            }
        });
    }

    public static open(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-datepicker", "open");
    }
}
