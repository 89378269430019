//@author: mandy
//https://ng-bootstrap.github.io/#/components/datepicker/examples
//https://github.com/ng-bootstrap/ng-bootstrap/issues/2391

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NgbCalendar, NgbDate, NgbDateStruct, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { PreferenceService } from "../../services/preference.service";
import { S25Dateformat } from "../s25-dateformat/s25.dateformat.service";
import { Preference } from "../../pojo/PeferenceI";

@TypeManagerDecorator("s25-datepicker")
@Component({
    selector: "s25-datepicker",
    template: `
        @if (inline && init && !multipleDate) {
            <div id="inline{{ id }}">
                <ngb-datepicker
                    #dp
                    [(ngModel)]="model"
                    (navigate)="date = $event.next"
                    [footerTemplate]="footerTemplate"
                    [startDate]="date"
                    (dateSelect)="onChangeHandler(model)"
                    [minDate]="minDateObj"
                    [maxDate]="maxDateObj"
                    [firstDayOfWeek]="firstDayOfWeek"
                ></ngb-datepicker>
            </div>
        }

        @if (!inline && init) {
            <div id="popup{{ id }}" class="form-group">
                @if (inputLabel) {
                    <label [for]="id" class="ngBold c-margin-right--quarter">{{ inputLabel }}</label>
                }
                <input
                    class="c-input"
                    [ngClass]="inputClass"
                    name="dp"
                    placement="bottom"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (dateSelect)="onDateSelect($event)"
                    [footerTemplate]="footerTemplate"
                    [value]="allowEmpty ? finalModelValue || '' : finalModelValue"
                    [startDate]="date"
                    (click)="d.toggle()"
                    (keydown.enter)="toggle($event)"
                    [id]="id"
                    (change)="onChange($event)"
                    (closed)="onCloseHandler()"
                    [minDate]="minDateObj"
                    [maxDate]="maxDateObj"
                    [container]="popoverOnBody ? 'body' : null"
                    [placeholder]="placeholder"
                    [firstDayOfWeek]="firstDayOfWeek"
                    autocomplete="off"
                />
            </div>
        }

        @if (inline && init && multipleDate) {
            <div id="inline{{ id }}">
                <ngb-datepicker
                    [(ngModel)]="model"
                    ngbDatepicker
                    (navigate)="date = $event.next"
                    [dayTemplate]="customDay"
                    [startDate]="date"
                    (dateSelect)="onChangeHandler(model)"
                    [minDate]="minDateObj"
                    [maxDate]="maxDateObj"
                    [firstDayOfWeek]="firstDayOfWeek"
                ></ngb-datepicker>
                <ng-template
                    #customDay
                    let-date
                    let-currentMonth="currentMonth"
                    let-currentYear="currentYear"
                    let-selected="selected"
                >
                    <ng-container [ngTemplateOutlet]="isChoose(date) ? chosen : notChosen"></ng-container>
                    <ng-template #chosen>
                        <div class="custom-day aw-button--primary">{{ date.day }}</div>
                    </ng-template>
                    <ng-template #notChosen>
                        <div class="custom-day" [class.text-muted]="date.month !== currentMonth">{{ date.day }}</div>
                    </ng-template>
                </ng-template>
            </div>
        }

        <ng-template #footerTemplate>
            @if (modelValue.showToday) {
                <div class="col text-center c-margin-bottom--quarter">
                    <a
                        class="s25-ng divTodayLink"
                        tabindex="0"
                        (click)="selectToday(); close()"
                        (keydown.enter)="selectToday(); close()"
                        >Select Today</a
                    >
                </div>
            }
        </ng-template>
    `,
    styles: [
        ".editable-datepicker{background-color: inherit; border: none !important; box-shadow: none;}",
        ".ngb-dp-today {font-weight: bold;background-color: #e2e6ea;}",
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DatepickerComponent implements OnInit, OnChanges {
    /**
     * modelValue: {date: Date,
     *              showToday: true,
     *              occurrencesDates: ["Fri Aug 20 2021", "Tue Aug 24 2021", "Tue Aug 31 2021", "Fri Sep 10 2021"]}
     */
    static count: number = 0;

    @Input() inputLabel: string = undefined;
    @Input() inputId: string = undefined;
    @Input() inputClass: string;
    @Input() modelValue: any = undefined;
    @Input() prefType: Preference.PrefType = "U";
    @Input() inline: boolean = false;
    @Input() multipleDate: boolean = false;
    @Input() onClose: Function;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() autoSelectToday?: boolean = true;
    @Input() popoverOnBody?: boolean = false;
    @Input() allowEmpty?: boolean = false;
    @Output() modelValueChange = new EventEmitter();
    @Output() forceClose = new EventEmitter<void>();
    @Input() placeholder?: string = "Select a date";
    @Input() isDateSelected?: (date: NgbDate) => boolean; // Optional callback for checking whether a date is selected

    datepicker: NgbInputDatepicker;
    date: any;
    minDateObj: any;
    maxDateObj: any;

    @ViewChild(NgbInputDatepicker, { static: false })
    set componentDatepicker(datepicker: NgbInputDatepicker) {
        this.datepicker = datepicker;
        if (this.initOpen) {
            this.open();
        }
    }

    init = false;
    initOpen = false;
    dateOrder: string = "MDY";
    dateSep: string;
    month: string;
    day: string;
    id: string;
    initModelValue: any;
    finalModelValue: any;
    dateFormat: any;
    model: NgbDateStruct; //{year: year, month: month, day: day}
    firstDayOfWeek: number = 7;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private calendar: NgbCalendar,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        S25DatepickerComponent.count++;
        this.id = "date-" + S25DatepickerComponent.count;
    }

    ngOnInit() {
        this.minDateObj = S25Util.date.toYMD(this.minDate);
        this.maxDateObj = S25Util.date.toYMD(this.maxDate);
        this.zone.run(() => {
            this.getPrefDateOrder().then(() => {
                if (this.modelValue?.date) {
                    // can be Date | string
                    this.modelValue.date = S25Util.date.parse(this.modelValue.date);
                    this.date = {
                        year: this.modelValue.date.getFullYear(),
                        month: this.modelValue.date.getMonth() + 1,
                        day: this.modelValue.date.getDate(),
                    };
                    this.selectDate(this.date, true);
                }
                if (this.inline && !this.multipleDate && this.autoSelectToday) this.selectToday();
                this.inputId ? (this.id = this.inputId) : "";
                this.refresh();
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minDate) {
            this.minDateObj = S25Util.date.toYMD(this.minDate);
        }
    }

    selectDate(date: NgbDateStruct, skipEmit?: boolean) {
        this.model = date;
        this.onDateSelect(date, skipEmit);
    }

    /*
    For text entry, hitting enter needs to
        EITHER save the entered date, OR
        open the datepicker.
    */
    toggle(e: any) {
        if (!e.target.value && this.allowEmpty) {
            if (!this.finalModelValue) this.datepicker.open();
            else {
                this.finalModelValue = "";
                this.onChangeHandler("");
            }
            return;
        }

        let newDt = S25Util.date.getDate(e.target.value);
        if (S25Util.date.equal(newDt, this.finalModelValue)) {
            this.datepicker.open();
        } else {
            this.onChangeHandler(S25Util.date.toYMD(newDt));
        }
    }

    getStartDate() {
        let d = S25Util.date.getDate(this.finalModelValue);
        this.date = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
    }

    //inline get today/select today
    selectToday() {
        this.selectDate(this.calendar.getToday());
    }

    getPrefDateOrder() {
        return S25Util.all({
            pref: PreferenceService.getPreferences(
                ["DateOrder", "DateSep", "Month", "Day", "CalendarView"],
                this.prefType,
            ),
        }).then((resp) => {
            this.dateOrder = resp.pref.DateOrder.value;
            this.dateSep = resp.pref.DateSep.value;
            this.month = resp.pref.Month.value;
            this.day = resp.pref.Day.value;
            this.dateFormat = this.getDateFormat();

            if (this.modelValue && this.modelValue.date) {
                this.finalModelValue = S25Datefilter.transform(this.modelValue.date, this.dateFormat);
            }

            this.initModelValue = S25Util.deepCopy(this.modelValue);
            this.firstDayOfWeek = Number(resp.pref.CalendarView.value);
            // this.modelValue && this.modelValue.date && this.onChangeHandler({year: this.modelValue.date.getFullYear(),
            //                                                                 month: this.modelValue.date.getMonth(),
            //                                                                 day: this.modelValue.date.getDate() });
            this.init = true;
            this.refresh();
        });
    }

    // get user/system dateformat
    getDateFormat() {
        let dateFormat = S25Dateformat.getDateformat({
            dateorder: this.dateOrder,
            datesep: this.dateSep,
            day: this.day,
            month: this.day,
        });
        return dateFormat;
    }

    onChangeHandler(newValue: any, skipEmit?: boolean) {
        if (newValue) {
            let d = S25Util.date.createDate(newValue.year, newValue.month, newValue.day);
            this.finalModelValue = S25Datefilter.transform(d, this.dateFormat);
            this.getStartDate();
            !skipEmit && this.modelValueChange.emit(d);
        }

        let input = this.elementRef.nativeElement.querySelector("input");
        if (input && input.value === "") {
            this.elementRef.nativeElement.querySelector("input").value = this.finalModelValue;
            this.cd.detectChanges();
        }
    }

    //change NgbDatePicker format, tried to use NgbDatePicker Custom date adapter and formatter, doesn't work 25live dateformat
    onDateSelect(newValue: any, skipEmit?: boolean) {
        this.onChangeHandler(newValue, skipEmit);
        this.refresh();
    }

    //enter date input
    onChange(e: any) {
        const isValid = S25Util.date.isValid(e.target.value);
        if (!e.target.value && this.allowEmpty) return this.modelValueChange.emit(null);
        if (isValid) {
            let dt = S25Util.date.getDate(e.target.value);
            this.onChangeHandler(S25Util.date.toYMD(dt));
        } else {
            this.selectToday();
            this.refresh();
        }
    }

    /// cuz not use [(ngModel)], close datepicker work around
    close = () => {
        this.forceClose.emit();
        if (this.elementRef.nativeElement.querySelector("ngb-datepicker") !== null) {
            this.elementRef.nativeElement.querySelector("ngb-datepicker").remove("show");
        }
        this.elementRef.nativeElement.querySelector("input")?.click();
        this.elementRef.nativeElement.querySelector("input")?.focus();
    };

    isChoose(date: NgbDate) {
        if (this.isDateSelected) return this.isDateSelected(date);
        let occurrences = this.modelValue.occurrencesDates || this.initModelValue.occurrencesDates;
        if (occurrences && occurrences.length) {
            for (let i = 0; i < occurrences.length; i++) {
                const selectedDate = new Date(occurrences[i]);
                const day: number = selectedDate.getDate();
                const month: number = selectedDate.getMonth() + 1;
                const year: number = selectedDate.getFullYear();
                if (day === date.day && month === date.month && year === date.year) {
                    return true;
                }
            }
        }
    }

    open = () => {
        if (!this.datepicker) {
            this.initOpen = true;
        } else {
            if (!this.datepicker.isOpen()) {
                this.datepicker.open();
            }
        }
    };

    onCloseHandler = () => {
        this.onClose && this.onClose();
    };

    refresh = () => {
        try {
            this.cd.detectChanges();
        } catch (err: any) {}
    };
}
