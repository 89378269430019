import { NgModule } from "@angular/core";
import { S25DndSortableDirective } from "./s25.dnd.sortable.directive";
import { CommonModule } from "@angular/common";
import { S25DndSortableItemDirective } from "./s25.dnd.sortable.item.directive";
import { S25AutoScrollDirective } from "./s25.auto.scroll.directive";
import { S25DragHandleComponent } from "./s25.drag.handle.component";

@NgModule({
    imports: [CommonModule],
    exports: [S25DndSortableDirective, S25DndSortableItemDirective, S25AutoScrollDirective, S25DragHandleComponent],
    providers: [S25DragHandleComponent],
    declarations: [
        S25DndSortableDirective,
        S25DndSortableItemDirective,
        S25AutoScrollDirective,
        S25DragHandleComponent,
    ],
})
export class S25DndSortableModule {}
