import { NgModule } from "@angular/core";
import { S25DayChooserComponent } from "./s25.day.chooser.component";
import { CommonModule } from "@angular/common";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";

@NgModule({
    declarations: [S25DayChooserComponent],
    imports: [CommonModule, S25MultiselectModule],
    providers: [S25DayChooserComponent],
    exports: [S25DayChooserComponent],
})
export class S25DayChooserModule {
    constructor() {}
}
