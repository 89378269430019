//@author: Mandy
import { Flavor } from "../../pojo/Util";

declare global {
    interface Window {
        angBridge: any;
    }
}
export class S25DatetimePrefssUtil {
    public static timeZoneOptions: any = [
        {
            value: 1,
            abbr: "MEZ-1MESZ",
            name: "Mitteleuropaeische Zeit, Austria, Germany, Switzerland",
            IANA: "MET",
        },
        {
            value: 2,
            abbr: "CET-1CEST",
            name: "Central European Time, Belgium, Denmark, France, Italy, Netherlands, Norway, Portugal, Spain, Sweden",
            IANA: "CET",
        },
        {
            value: 3,
            abbr: "EET-2EETDST",
            name: "East European Time, Bulgaria, Finland, Greece, Romania, Turkey, Ukraine",
            IANA: "EET",
        },
        { value: 4, abbr: "GMT0BST", name: "Greenwich Mean Time, United Kingdom, Ireland", IANA: "GMT" },
        { value: 5, abbr: "WET0WETDST", name: "Western European Time, Spain (Canary Is.), Faroe Island", IANA: "WET" },
        { value: 6, abbr: "PWT0PST", name: "Portuguese Winter Time, Portugal (Madeira)", IANA: "Europe/Lisbon" },
        { value: 7, abbr: "MST-3MDT", name: "Moscow Time, Russia (Moscow)", IANA: "Europe/Moscow" },
        {
            value: 8,
            abbr: "EUT1EUTDST",
            name: "European (1) Time, Greenland (Scoresbysund), Portugal (Azores)",
            IANA: "Atlantic/Azores",
        },
        { value: 9, abbr: "EUT-2EUTDST", name: "European (-2) Time, Lebanon", IANA: "Asia/Beirut" },
        { value: 10, abbr: "EUT-3EUTDST", name: "European (-3) Time, Ukraine (Simferopol)", IANA: "Europe/Kyiv" },
        { value: 11, abbr: "EUT-4EUTDST", name: "European (-4) Time, Georgia", IANA: "Asia/Tbilisi" },
        { value: 12, abbr: "EUT-6EUTDST", name: "European (-6) Time, Kazakhstan", IANA: "Asia/Aqtau" },
        { value: 13, abbr: "EUT-8EUTDST", name: "European (-8) Time, Mongolia", IANA: "Asia/Ulaanbaatar" },
        {
            value: 14,
            abbr: "RFT-2RFTDST",
            name: "Russian Fed. Zone 1 Time, Russian Fed. (Zone 1)",
            IANA: "Europe/Kaliningrad",
        },
        {
            value: 15,
            abbr: "RFT-3RFTDST",
            name: "Russian Fed. Zone 2 Time, Russian Fed. (Zone 2)",
            IANA: "Europe/Moscow",
        },
        {
            value: 16,
            abbr: "RFT-4RFTDST",
            name: "Russian Fed. Zone 3 Time, Russian Fed. (Zone 3)",
            IANA: "Europe/Samara",
        },
        {
            value: 17,
            abbr: "RFT-5RFTDST",
            name: "Russian Fed. Zone 4 Time, Russian Fed. (Zone 4)",
            IANA: "Asia/Yekaterinburg",
        },
        { value: 18, abbr: "RFT-6RFTDST", name: "Russian Fed. Zone 5 Time, Russian Fed. (Zone 5)", IANA: "Asia/Omsk" },
        {
            value: 19,
            abbr: "RFT-7RFTDST",
            name: "Russian Fed. Zone 6 Time, Russian Fed. (Zone 6)",
            IANA: "Asia/Krasnoyarsk",
        },
        {
            value: 20,
            abbr: "RFT-8RFTDST",
            name: "Russian Fed. Zone 7 Time, Russian Fed. (Zone 7)",
            IANA: "Asia/Irkutsk",
        },
        {
            value: 21,
            abbr: "RFT-9RFTDST",
            name: "Russian Fed. Zone 8 Time, Russian Fed. (Zone 8)",
            IANA: "Asia/Yakutsk",
        },
        {
            value: 22,
            abbr: "RFT-10RFTDST",
            name: "Russian Fed. Zone 9 Time, Russian Fed. (Zone 9)",
            IANA: "Asia/Vladivostok",
        },
        {
            value: 23,
            abbr: "RFT-11RFTDST",
            name: "Russian Fed. Zone 10 Time, Russian Fed. (Zone 10)",
            IANA: "Asia/Magadan",
        },
        {
            value: 24,
            abbr: "RFT-12RFTDST",
            name: "Russian Fed. Zone 11 Time, Russian Fed. (Zone 11)",
            IANA: "Asia/Kamchatka",
        },
        {
            value: 25,
            abbr: "PST8PDT",
            name: "Pacific Time, U.S.A. (Pacific), Canada (Pacific & Yukon), Mexico (Baja N.)",
            IANA: "PST8PDT",
        },
        {
            value: 26,
            abbr: "MST7MDT",
            name: "Mountain Time, U.S.A. (Mountain), Canada (Mountain), Mexico (Baja S.)",
            IANA: "MST7MDT",
        },
        { value: 27, abbr: "MST7", name: "Mountain Time, U.S.A. (Arizona)", IANA: "America/Phoenix" },
        {
            value: 28,
            abbr: "CST6CDT",
            name: "Central Time, U.S.A (Central), Canada (Central), Mexico",
            IANA: "CST6CDT",
        },
        {
            value: 29,
            abbr: "EST5EDT",
            name: "Eastern Time, U.S.A. (Eastern), Canada (Eastern), Bahamas, Haiti, Turks & Caicos",
            IANA: "EST5EDT",
        },
        {
            value: 30,
            abbr: "AST4ADT",
            name: "Atlantic Time, Canada (Atlantic), Bermuda, Greenland (Thule)",
            IANA: "Atlantic/Bermuda",
        },
        { value: 32, abbr: "AST10ADT", name: "Aleutian Time, U.S.A. (Aleutian)", IANA: "America/Adak" },
        { value: 33, abbr: "AKST9YDT", name: "Alaskan Time, U.S.A. (Alaska)", IANA: "US/Alaska" },
        { value: 34, abbr: "NST3:30NDT", name: "Newfoundland Time, Canada (Newfoundland)", IANA: "America/St_Johns" },
        {
            value: 35,
            abbr: "NAST3NADT",
            name: "North American (3) Time, Saint Pierre &; Miquelon",
            IANA: "America/Miquelon",
        },
        { value: 36, abbr: "NAST9NADT", name: "North American (9) Time, U.S.A. (Alaska)", IANA: "US/Alaska" },
        { value: 37, abbr: "TTST4", name: "Trinidad & Tobago Time, Trinidad & Tobago", IANA: "America/Port_of_Spain" },
        {
            value: 38,
            abbr: "SAT3",
            name: "South America Time, Argentina, French Guiana, Uruguay",
            IANA: "America/Argentina/Buenos_Aires",
        },
        {
            value: 39,
            abbr: "EBST3EBDT",
            name: "Eastern Brazil Time, Brazil (East: Including All Coast and Brasilia)",
            IANA: "Brazil/East",
        },
        { value: 40, abbr: "WBST4WBDT", name: "Western Brazil Time, Brazil (West)", IANA: "Brazil/West" },
        { value: 41, abbr: "AST5", name: "Trinity of Acre Time, Brazil (Trinity of Acre)", IANA: "Brazil/Acre" },
        {
            value: 42,
            abbr: "FST2",
            name: "Fernando De Noronha Time, Brazil (Fernando De Noronha)",
            IANA: "America/Noronha",
        },
        { value: 43, abbr: "CST4CDT", name: "Chilean Time, Chile", IANA: "Chile/Continental" },
        { value: 44, abbr: "EIST6EIDT", name: "Easter Is. Time, Chile (Easter Is.)", IANA: "Chile/EasterIsland" },
        { value: 45, abbr: "MST-8", name: "Malaysia Time, Malaysia", IANA: "Asia/Kuala_Lumpur" },
        { value: 46, abbr: "CST-8", name: "China Time, China, Hong Kong, Taiwan", IANA: "Asia/Hong_Kong" },
        {
            value: 47,
            abbr: "CST-9:30CDT",
            name: "Australian Central Time, Australia (South Australia and Broken Hill)",
            IANA: "Australia/South",
        },
        {
            value: 48,
            abbr: "EST-10EDT",
            name: "Australian Eastern Time, Australia (N.S.W & Capitol Territory & Victoria)",
            IANA: "Australia/Victoria",
        },
        {
            value: 49,
            abbr: "LHT-10:30LHDT",
            name: "Lord Howe Is. Time, Australia (Lord Howe Is.)",
            IANA: "Australia/Lord_Howe",
        },
        { value: 50, abbr: "TST-10TDT", name: "Tasmanian Time, Australia (Tasmania)", IANA: "Australia/Tasmania" },
        { value: 51, abbr: "NZST-12NZDT", name: "New Zealand Time, New Zealand", IANA: "NZ" },
        {
            value: 52,
            abbr: "CIST-12:45CIDT",
            name: "Chatham Is. Time, New Zealand (Chatham Is.)",
            IANA: "Pacific/Chatham",
        },
        { value: 53, abbr: "SAST-2", name: "South Africa Time, South Africa", IANA: "Africa/Johannesburg" },
        { value: 54, abbr: "EST-2EDT", name: "Egypt Time, Egypt", IANA: "Egypt" },
        { value: 55, abbr: "UAEST-4", name: "United Arab Emirates Time, United Arab Emirates", IANA: "Asia/Dubai" },
        { value: 56, abbr: "IST-3IDT", name: "Iraq Time, Iraq", IANA: "Asia/Baghdad" },
        { value: 57, abbr: "JST-2JDT", name: "Jordan Time, Jordan", IANA: "Asia/Amman" },
        { value: 58, abbr: "SST-2SDT", name: "Syrian Time, Syria", IANA: "Asia/Damascus" },
        {
            value: 59,
            abbr: "UCT0",
            name: "Universal Coordinated Time, Iceland, Mali, Ivory Coast, Gambia, Ghana, Guinea, Liberia, Morocco, Senegal",
            IANA: "UCT",
        },
        { value: 60, abbr: "UCT1", name: "Universal Coordinated Time 1, Cape Verde Is.", IANA: "Atlantic/Cape_Verde" },
        { value: 61, abbr: "UCT2", name: "Universal Coordinated Time 2", IANA: "Etc/GMT+2" },
        { value: 62, abbr: "UCT3", name: "Universal Coordinated Time 3, Suriname", IANA: "America/Paramaribo" },
        {
            value: 63,
            abbr: "UCT4",
            name: "Universal Coordinated Time 4, Barbados, Bolivia, Dominican Rep., Guadeloupe, Guyana, Puerto Rico, Venezuela",
            IANA: "America/Caracas",
        },
        {
            value: 64,
            abbr: "UCT5",
            name: "Universal Coordinated Time 5, Cayman Is., Colombia, Ecuador, Jamaica, Panama",
            IANA: "America/Panama",
        },
        {
            value: 65,
            abbr: "UCT6",
            name: "Universal Coordinated Time 6, Belize, Costa Rica, El Salvador, Guatemala, Honduras, Nicaragua",
            IANA: "America/Belize",
        },
        { value: 66, abbr: "UCT7", name: "Universal Coordinated Time 7", IANA: "Etc/GMT+7" },
        { value: 67, abbr: "UCT8", name: "Universal Coordinated Time 8", IANA: "Etc/GMT+8" },
        { value: 68, abbr: "UCT9", name: "Universal Coordinated Time 9", IANA: "Etc/GMT+9" },
        {
            value: 69,
            abbr: "HST10",
            name: "Hawaiian Time Time, U.S.A. (Hawaii), Cook Is., Tahiti, Johnston Is.",
            IANA: "US/Hawaii",
        },
        {
            value: 70,
            abbr: "SST11",
            name: "Samoan Time, American Samoa, Midway Is., Niue Is., Samoa",
            IANA: "US/Samoa",
        },
        {
            value: 71,
            abbr: "WAT-1",
            name: "West Africa Time, Algeria, Angola, Cameroon, Chad, Congo, Gabon, Niger, Nigeria, Tunisia",
            IANA: "Africa/Ndjamena",
        },
        {
            value: 72,
            abbr: "CAT-2",
            name: "Central Africa Time, Botswana, Burundi, Mozambique, Rwanda, Sudan, Swaziland, Zambia, Zimbabwe",
            IANA: "Africa/Maputo",
        },
        {
            value: 73,
            abbr: "EAT-3",
            name: "East Africa Time, Ethiopia, Kenya, Kuwait, Madagscar, Qatar, Saudi Arabia, Somalia, Tanzania",
            IANA: "Asia/Riyadh",
        },
        {
            value: 74,
            abbr: "UCT-4",
            name: "Universal Coordinated Time -4, Mauritius, Oman, Reunion, Seychelles",
            IANA: "Asia/Dubai",
        },
        {
            value: 75,
            abbr: "UCT-5",
            name: "Universal Coordinated Time -5, Maldives, Pakistan, Tajikistan, Turkmenistan, Uzbekistan",
            IANA: "Indian/Maldives",
        },
        { value: 76, abbr: "IST-5:30", name: "Indian Time, India, Sri Lanka", IANA: "Asia/Kolkata" },
        { value: 77, abbr: "UCT-5:45", name: "Universal Coordinated Time -5:45, Nepal", IANA: "Asia/Kathmandu" },
        { value: 78, abbr: "UCT-6", name: "Universal Coordinated Time -6, Bangladesh, Bhutan", IANA: "Asia/Thimphu" },
        {
            value: 79,
            abbr: "UCT-6:30",
            name: "Universal Coordinated Time -6:30, Myanmar, Cocos (Keeling) Is.",
            IANA: "Asia/Yangon",
        },
        {
            value: 80,
            abbr: "UCT-7",
            name: "Universal Coordinated Time -7, Cambodia, Kampuchea, Indonesia (West), Thailand, Vietnam",
            IANA: "Asia/Bangkok",
        },
        { value: 81, abbr: "WST-8", name: "Western Australia Time, Australia (Western), Brunei", IANA: "Asia/Kuching" },
        { value: 82, abbr: "UCT-9", name: "Universal Coordinated Time -9, Indonesia (East)", IANA: "Asia/Jayapura" },
        {
            value: 83,
            abbr: "CST-9:30",
            name: "Central Australian Time, Australia (Northern Territory)",
            IANA: "Australia/North",
        },
        {
            value: 84,
            abbr: "EST-10",
            name: "Eastern Australian Time, Australia (Queensland)",
            IANA: "Australia/Queensland",
        },
        {
            value: 85,
            abbr: "UCT-11",
            name: "Universal Coordinated Time -11, New Caledonia, Solomon Is., Vanuatu",
            IANA: "Pacific/Guadalcanal",
        },
        {
            value: 86,
            abbr: "UCT-11:30",
            name: "Universal Coordinated Time -11:30, Norfolk Is.",
            IANA: "Pacific/Norfolk",
        },
        {
            value: 87,
            abbr: "UCT-12",
            name: "Universal Coordinated Time -12, Fiji, Kiribati, Nauru, Tuvalu, Wake Is., Wallis & Futana Is.",
            IANA: "Pacific/Tarawa",
        },
        { value: 88, abbr: "UCT-13", name: "Universal Coordinated Time -13, Tonga", IANA: "Pacific/Tongatapu" },
        { value: 89, abbr: "JST-9", name: "Japanese Time, Japan", IANA: "Japan" },
        { value: 90, abbr: "KST-9", name: "Korean Time, Korea", IANA: "Asia/Seoul" },
        {
            value: 91,
            abbr: "GST-10",
            name: "Western Pacific Time, Guam, Papua New Guinea",
            IANA: "Pacific/Port_Moresby",
        },
        { value: 92, abbr: "SASK6", name: "Saskatchewan", IANA: "Canada/Saskatchewan" },
    ];

    public static timeZoneToIANA: Record<number, Flavor<string, "IANATimezone">> = {
        1: "MET",
        2: "CET",
        3: "EET",
        4: "GMT",
        5: "WET",
        6: "Europe/Lisbon",
        7: "Europe/Moscow",
        8: "Atlantic/Azores",
        9: "Asia/Beirut",
        10: "Europe/Kyiv",
        11: "Asia/Tbilisi",
        12: "Asia/Aqtau",
        13: "Asia/Ulaanbaatar",
        14: "Europe/Kaliningrad",
        15: "Europe/Moscow",
        16: "Europe/Samara",
        17: "Asia/Yekaterinburg",
        18: "Asia/Omsk",
        19: "Asia/Krasnoyarsk",
        20: "Asia/Irkutsk",
        21: "Asia/Yakutsk",
        22: "Asia/Vladivostok",
        23: "Asia/Magadan",
        24: "Asia/Kamchatka",
        25: "PST8PDT",
        26: "MST7MDT",
        27: "America/Phoenix",
        28: "CST6CDT",
        29: "EST5EDT",
        30: "Atlantic/Bermuda",
        32: "America/Adak",
        33: "US/Alaska",
        34: "America/St_Johns",
        35: "America/Miquelon",
        36: "US/Alaska",
        37: "America/Port_of_Spain",
        38: "America/Argentina/Buenos_Aires",
        39: "Brazil/East",
        40: "Brazil/West",
        41: "Brazil/Acre",
        42: "America/Noronha",
        43: "Chile/Continental",
        44: "Chile/EasterIsland",
        45: "Asia/Kuala_Lumpur",
        46: "Asia/Hong_Kong",
        47: "Australia/South",
        48: "Australia/Victoria",
        49: "Australia/Lord_Howe",
        50: "Australia/Tasmania",
        51: "NZ",
        52: "Pacific/Chatham",
        53: "Africa/Johannesburg",
        54: "Egypt",
        55: "Asia/Dubai",
        56: "Asia/Baghdad",
        57: "Asia/Amman",
        58: "Asia/Damascus",
        59: "UCT",
        60: "Atlantic/Cape_Verde",
        61: "Etc/GMT+2",
        62: "America/Paramaribo",
        63: "America/Caracas",
        64: "America/Panama",
        65: "America/Belize",
        66: "Etc/GMT+7",
        67: "Etc/GMT+8",
        68: "Etc/GMT+9",
        69: "US/Hawaii",
        70: "US/Samoa",
        71: "Africa/Ndjamena",
        72: "Africa/Maputo",
        73: "Asia/Riyadh",
        74: "Asia/Dubai",
        75: "Indian/Maldives",
        76: "Asia/Kolkata",
        77: "Asia/Kathmandu",
        78: "Asia/Thimphu",
        79: "Asia/Yangon",
        80: "Asia/Bangkok",
        81: "Asia/Kuching",
        82: "Asia/Jayapura",
        83: "Australia/North",
        84: "Australia/Queensland",
        85: "Pacific/Guadalcanal",
        86: "Pacific/Norfolk",
        87: "Pacific/Tarawa",
        88: "Pacific/Tongatapu",
        89: "Japan",
        90: "Asia/Seoul",
        91: "Pacific/Port_Moresby",
        92: "Canada/Saskatchewan",
    };
}
