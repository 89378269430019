import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { S25DeleteComponent } from "./s25.delete.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25DeleteInlineComponent } from "./s25.delete.inline.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";

@NgModule({
    declarations: [S25DeleteComponent, S25DeleteInlineComponent],
    imports: [CommonModule, S25ToggleButtonModule, S25LoadingInlineModule, S25IconModule],
    providers: [S25DeleteComponent, S25DeleteInlineComponent],
    exports: [S25DeleteComponent, S25DeleteInlineComponent],
})
export class S25DeleteModule {
    constructor() {}
}
