//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25DatetimePrefsComponent } from "./s25.datetime.prefs.component";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25DarkmodeModule } from "../s25-darkmode/s25.darkmode.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";

@NgModule({
    declarations: [S25DatetimePrefsComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25TimepickerModule,
        S25DatepickerModule,
        S25DarkmodeModule,
        S25DropdownPaginatedModule,
    ],
    providers: [S25DatetimePrefsComponent],
    exports: [S25DatetimePrefsComponent],
})
export class S25DatetimePrefsModule {
    constructor() {}
}
