//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25DatepickerComponent } from "./s25.datepicker.component";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { S25MultiDatepickerComponent } from "./s25.multi.datepicker.component";
import { S25OccurrenceDatepickerComponent } from "./s25.occurrence.datepicker.component";
import { S25DatepickerInlineComponent } from "./s25.datepicker.inline.component";

@NgModule({
    declarations: [
        S25DatepickerComponent,
        S25MultiDatepickerComponent,
        S25OccurrenceDatepickerComponent,
        S25DatepickerInlineComponent,
    ],
    imports: [CommonModule, FormsModule, NgbModule, NgbDatepickerModule],
    providers: [
        S25DatepickerComponent,
        S25MultiDatepickerComponent,
        S25OccurrenceDatepickerComponent,
        S25DatepickerInlineComponent,
    ],
    exports: [
        S25DatepickerComponent,
        S25MultiDatepickerComponent,
        S25OccurrenceDatepickerComponent,
        S25DatepickerInlineComponent,
    ],
})
export class S25DatepickerModule {
    constructor() {}
}
