import { AfterViewInit, Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, Renderer2 } from "@angular/core";
import { Bind } from "../../decorators/bind.decorator";

@Directive({ selector: "[s25-ng-dnd-sortable-item]" })
export class S25DndSortableItemDirective implements AfterViewInit, OnDestroy {
    @Input() index: number; // Has to be unique in sortable
    @Input() belongsTo?: any[]; // Only needed for nested sortables

    listeners: ReturnType<Renderer2["listen"]>[] = [];

    constructor(
        public elementRef: ElementRef,
        private zone: NgZone,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit() {
        this.setEventListeners();
    }

    setEventListeners() {
        const elem = this.elementRef.nativeElement;
        this.zone.runOutsideAngular(() => {
            const events = [
                "dragstart",
                "dragend",
                "dragenter",
                "dragover",
                "keydown.enter",
                "keydown.space",
                "keydown.arrowup",
                "keydown.arrowdown",
                "keydown.arrowleft",
                "keydown.arrowright",
                "keydown.tab",
                "keydown.shift.tab",
            ];

            for (const event of events) {
                this.listeners.push(this.renderer.listen(elem, event, this.onEvent));
            }
        });
    }

    ngOnDestroy() {
        this.removeEventListeners();
    }

    removeEventListeners() {
        for (const listener of this.listeners) listener();
    }

    @HostBinding("class.dragging") dragging = false; // Add class when dragging with mouse
    @Input() @HostBinding("draggable") draggable = true; // Make item draggable
    @HostBinding("tabindex") tabIndex = 0; // Make item tabbable
    @HostBinding("attr.role") ariaRole = "listitem"; // Allow description
    @HostBinding("attr.aria-roledescription") // Description for screen readers
    ariaRoleDescription = "Draggable item. Press enter to grab or drop."; // Has instructions because I could not get aria-describedby to work?

    // Add data to event
    // Wanted to use @HostBinding for this, but the test runner was complaining about custom attributes not existing
    @Bind
    onEvent(event: any) {
        if (event.type === "keydown" && event.target !== this.elementRef.nativeElement) return; // Only act on keyboard events originating on item
        if (event.type === "dragstart") this.dragging = true;
        if (event.type === "dragend") this.dragging = false;
        event["s25-dnd-sortable-item--index"] = this.index;
        event["s25-dnd-sortable-item--belongsTo"] = this.belongsTo;
        event["s25-dnd-sortable-item"] = true;
    }
}
