import { Component } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-drag-handle")
@Component({
    selector: "s25-ng-drag-handle",
    template: `
        <svg class="c-svgIcon">
            <title>Move</title>
            <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#drag--indicator"
            ></use>
        </svg>
    `,
})
export class S25DragHandleComponent {}
