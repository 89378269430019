import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-delete-inline")
@Component({
    selector: "s25-ng-delete-inline",
    template: `
        @if (confirmMsg) {
            <div class="rose-form--error"></div>
            <div class="rose-form--organization-rating-info">
                <div class="msg-container">
                    <s25-ng-icon [type]="'warningYield'"></s25-ng-icon>
                    <p class="rose-form--error-text c-margin-bottom--half" tabindex="0">
                        <strong>{{ confirmMsg }}</strong>
                    </p>
                </div>
                @if (listItems) {
                    <ul>
                        @for (item of listItems; track $index) {
                            <li tabindex="0">{{ item }}</li>
                        }
                    </ul>
                }
                <div class="button-group">
                    <button class="aw-button aw-button--danger c-margin-right--quarter" (click)="onConfirm.emit(true)">
                        Confirm
                    </button>
                    <button class="aw-button aw-button--danger--outline" (click)="onConfirm.emit(false)">Cancel</button>
                </div>
            </div>
        }
    `,
    styles: `
        :host ::ng-deep .c-svgIcon {
            color: #d62000;
            height: 2em;
            width: 2em;
        }

        :host ::ng-deep .msg-container {
            display: flex;
            gap: 1em;
            margin-inline: 1rem;
        }

        :host ::ng-deep ul {
            font-size: 0.8rem;
            margin: 0 25% 10px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DeleteInlineComponent {
    @Input() confirmMsg: string;
    @Input() listItems?: any[]; // optional array of affected items to list in confirmation message

    @Output() onConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();
}
