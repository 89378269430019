//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PreferenceService } from "../../services/preference.service";
import { S25Dateformat } from "../s25-dateformat/s25.dateformat.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { S25DatetimePrefssUtil } from "./s25.datetime.prefs.util";
import { ContactService } from "../../services/contact.service";
import { TimepickerApi } from "../s25-timepicker/timepicker.api";
import { Preference } from "../../pojo/PeferenceI";
import { Item } from "../../pojo/Item";
import { DropDownItem } from "../../pojo/DropDownItem";
import { AlertService } from "../../services/alert.service";
import { TaskService } from "../../services/task/task.service";
import { SearchService } from "../../services/search/search.service";
import Ids = Item.Ids;
import { FlsService } from "../../services/fls.service";

@TypeManagerDecorator("s25-datetime-prefs")
@Component({
    selector: "s25-datetime-prefs",
    template: `
        @if (this.prefType === "U") {
            <div class="c-margin-left--half">
                <h2 class="c-margin-bottom--half">Dark Mode</h2>
                <s25-darkmode></s25-darkmode>
                @if (this.task.hasFls) {
                    <h2 class="c-margin-bottom--half c-margin-top--double">Task Defaults</h2>
                    <div class="ngBold c-margin-bottom--quarter">Default Search</div>
                    <s25-ng-search-dropdown
                        [hasFav]="true"
                        [(chosen)]="task.selected"
                        [initialSearch]="task.selected"
                        (chosenChange)="validateTaskCount($event)"
                        [itemTypeId]="task.itemTypeId"
                        [allowNonQueryId]="true"
                        [searchEnabled]="true"
                        class="searchChooser"
                    ></s25-ng-search-dropdown>
                }
            </div>
        }

        @if (this.prefType === "U") {
            <div class="c-margin-left--half c-margin-top--double">
                <h2 class="c-margin-bottom--quarter">Date & Time Preferences</h2>
            </div>
        }

        @if (this.init) {
            <div [ngClass]="{ 'c-margin-left--half': this.prefType === 'U' }" id="{{ this.id }}">
                @if (this.prefType === "S") {
                    <h2 class="c-margin-bottom--single">Date & Time Defaults</h2>
                }
                <div class="c-margin-bottom--quarter ngFinePrint">
                    Example Date and Time Display: <strong>{{ this.dateTimeFormat }}</strong>
                </div>
                @if (this.dateOrder) {
                    <div class="c-margin-bottom--single c-margin-top--single">
                        <div class="ngBold c-margin-bottom--quarter">Date Order</div>
                        <input
                            type="radio"
                            [(ngModel)]="this.dateOrder"
                            name="dateOrder{{ this.prefType }}"
                            class="s25-input"
                            id="dateOrderMDY{{ this.prefType }}"
                            value="MDY"
                            (change)="this.onChange($event)"
                            aria-label="Month Day Year"
                        />
                        <label for="dateOrderMDY"> Month Day Year </label>
                        <input
                            type="radio"
                            [(ngModel)]="this.dateOrder"
                            name="dateOrder{{ this.prefType }}"
                            class="s25-input c-margin-left--single"
                            id="dateOrderDMY{{ this.prefType }}"
                            value="DMY"
                            (change)="this.onChange($event)"
                            aria-label="Day Month Year"
                        />
                        <label for="dateOrderDMY"> Day Month Year </label>
                        <input
                            type="radio"
                            [(ngModel)]="this.dateOrder"
                            name="dateOrder{{ this.prefType }}"
                            class="s25-input c-margin-left--single"
                            id="dateOrderYMD{{ this.prefType }}"
                            value="YMD"
                            (change)="this.onChange($event)"
                            aria-label=" Year Month Day"
                        />
                        <label for="dateOrderYMD"> Year Month Day </label>
                    </div>
                }
                <div class="c-margin-bottom--single">
                    <div class="ngBold c-margin-bottom--quarter">Time Display</div>
                    <input
                        type="radio"
                        [(ngModel)]="this.timeDisplay"
                        name="timeDisplay{{ this.prefType }}"
                        class="s25-input"
                        id="timeDisplay12{{ this.prefType }}"
                        value="12"
                        (change)="this.onChange($event)"
                        aria-label="12 hour clock"
                    />
                    <label for="timeDisplay12"> 12 hour clock </label>
                    <input
                        type="radio"
                        [(ngModel)]="this.timeDisplay"
                        name="timeDisplay{{ this.prefType }}"
                        class="s25-input c-margin-left--single"
                        id="timeDisplay24{{ this.prefType }}"
                        value="24"
                        (change)="this.onChange($event)"
                        aria-label="24 hour clock"
                    />
                    <label for="timeDisplay24"> 24 hour clock </label>
                </div>
                <div class="c-margin-bottom--single">
                    <label for="weekStartDay" class="ngBold c-margin-bottom--quarter"> Calendar Start Day </label>
                    <div>
                        @if (calViewOptions) {
                            <select class="ngListPageDropdown" [(ngModel)]="this.weekStartDay" name="weekStartDay">
                                @for (r of calViewOptions; track r) {
                                    <option [ngValue]="r.val">{{ r.txt }}</option>
                                }
                            </select>
                        }
                    </div>
                </div>
                @if (this.prefType === "U") {
                    <div class="c-margin-bottom--single">
                        <label for="timeZone" class="ngBold c-margin-bottom--quarter"> Calendar Default Weeks </label>
                        <div>
                            @if (numWeeksArr) {
                                <select
                                    id="proCalendarWeeks"
                                    class="ngListPageDropdown"
                                    [(ngModel)]="this.proCalendarWeeks"
                                    name="proCalendarWeeks"
                                >
                                    @for (n of numWeeksArr; track n; let i = $index) {
                                        <option [ngValue]="i + 1">{{ i + 1 }}</option>
                                    }
                                </select>
                            }
                        </div>
                    </div>
                }
                <div class="c-margin-bottom--single">
                    <label for="timezone" class="ngBold c-margin-bottom--quarter">
                        {{ this.prefType === "S" ? "Default Time Zone" : "Time Zone" }}
                    </label>
                    @if (this.prefType === "S") {
                        <p class="ngFinePrint c-margin-bottom--half">
                            The default time zone for 25Live cannot be changed here. Contact your Series25 Account
                            Manager for assistance.
                        </p>
                    }
                    <div>
                        @if (timeZoneOptions) {
                            <select
                                id="timeZone"
                                class="ngListPageDropdown"
                                [(ngModel)]="this.timezone"
                                name="timezone"
                                [disabled]="this.prefType === 'S' && this.username !== 'service25'"
                            >
                                @for (t of timeZoneOptions; track t) {
                                    <option [ngValue]="t.value">{{ t.name }}</option>
                                }
                            </select>
                        }
                    </div>
                </div>
                <div class="c-margin-bottom--single">
                    <label for="officeHours" class="ngBold c-margin-bottom--quarter">
                        Availability View Display Hours
                    </label>
                    @if (this.prefType === "S") {
                        <p class="ngFinePrint c-margin-bottom--half">
                            The default range of hours displayed on Availability views of locations and resources
                            throughout 25Live.
                        </p>
                    }
                    @if (this.prefType === "U") {
                        <p class="c-margin-top--single"></p>
                    }
                    <s25-timepicker
                        step="60"
                        inputId="displayStart"
                        [prefType]="this.prefType"
                        [(modelValue)]="this.displayStart"
                        (modelValueChange)="this.onChangeTime($event, 'displayStart')"
                    >
                    </s25-timepicker>
                    @if (this.finalDisplayStart === "") {
                        <p class="ngRed">Please select a time.</p>
                    }
                    <p class="c-margin-top--half c-margin-bottom--half">To:</p>
                    <s25-timepicker
                        step="60"
                        inputId="displayEnd"
                        [prefType]="this.prefType"
                        [(modelValue)]="this.displayEnd"
                        (modelValueChange)="this.onChangeTime($event, 'displayEnd')"
                    >
                    </s25-timepicker>
                    @if (this.finalDisplayEnd === "") {
                        <p class="ngRed">Please select a time.</p>
                    }
                    @if (this.endTimeError) {
                        <p class="ngRed">End time cannot be less than start time.</p>
                    }
                    <p>
                        <button class="aw-button aw-button--outline c-margin-top--half" (click)="selectAllHours()">
                            Select All Hours
                        </button>
                    </p>
                </div>
                @if (!reload) {
                    <div class="c-displayBlock c-padding-bottom--double">
                        <button
                            class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                            (click)="save()"
                            [disabled]="
                                this.finalDisplayStart === '' || this.finalDisplayEnd === '' || this.endTimeError
                            "
                        >
                            {{ this.loading ? "Saving...." : "Save" }}
                        </button>
                        <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">
                            Cancel
                        </button>
                    </div>
                }
                @if (reload) {
                    <div class="c-displayBlock c-padding-bottom--double">
                        <div class="text-center font-weight-bold">
                            <div>Preferences Saved.</div>
                            <div>Note: You must reload your browser for changes to take effect.</div>
                        </div>
                        <button class="aw-button aw-button--outline c-margin-top--single" (click)="windowReload()">
                            Reload Now
                        </button>
                    </div>
                }
                <!-- ******************  this is for testing purpose need to remove it if merge to dev  ********************* -->
                <!-- example inline -->
                <!-- <s25-datepicker [inline]="true" [prefType]="U" (modelValueChange)="onChangeDate($event)"></s25-datepicker>
                      <br> -->
                <!-- example popup -->
                <!-- <s25-datepicker [inline]="false" [(modelValue)]="this.test" [prefType]="U" (modelValueChange)="onChangeDate($event)" ></s25-datepicker> -->
                <!--  example multipleDate  -->
                <!-- <s25-datepicker [inline]="true"
                      [multipleDate]="true"
                      [(modelValue)]="this.testDates"
                      [prefType]="U"
                      (modelValueChange)="onChangeDate($event, 'occDate' )">
                    </s25-datepicker> -->
                <!-- ****************** END this is for testing purpose need to remove it if merge to dev  ********************* -->
            </div>
        }
    `,
    styles: `
        s25-ng-search-dropdown {
            display: flex;
            s25-generic-dropdown {
                min-width: 20em;
            }
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DatetimePrefsComponent implements OnInit {
    static count: number = 0;
    @Input() prefType: Preference.PrefType = "U";
    @Input() model: any = undefined;
    init: boolean = false;
    dateOrder: any;
    timeDisplay: any;
    calendarView: any;
    officeHours: any;
    timezone: any;
    initDateOrder: any;
    initTimeDisplay: any;
    initWeekStartDay: any;
    initOfficeHours: any;
    initTimezone: any;
    loading: boolean = false;
    today = new Date();
    weekStartDay: any;
    displayStart: any;
    displayEnd: any;
    calViewOptions = [
        { val: 7, txt: "Sunday" },
        { val: 1, txt: "Monday" },
    ];
    dateTimeFormat: any;
    dateSep: any;
    month: any;
    day: any;
    timeZoneOptions = S25DatetimePrefssUtil.timeZoneOptions;
    username: any;
    finalDisplayStart: any = undefined;
    finalDisplayEnd: any = undefined;
    endTimeError: boolean = false;
    timeFormat: any;
    numWeeksArr = new Array(16);
    task: { hasFls: boolean; itemTypeId: Item.Id; selected: DropDownItem; changed: boolean } = {
        selected: {},
        changed: false,
        itemTypeId: Ids.Task,
        hasFls: false,
    };

    proCalendarWeeks: any = 1;
    initProCalendarWeeks: any = 1;
    id: string;

    test: any;
    testDates: any = {};
    initTestDates: any = {};

    reload: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        S25DatetimePrefsComponent.count++;
        this.id = "dateTimePref-" + S25DatetimePrefsComponent.count;
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        S25Util.all({
            fls: FlsService.getFls(),
            prefs: this.getPrefs(),
            username: this.getCurrentUserName(),
        }).then((resp) => {
            this.task.hasFls = resp.fls.TASK_LIST !== "N";
            this.cd.detectChanges();
        });
    }
    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                [
                    "DateOrder",
                    "TimeDisplay",
                    "CalendarView",
                    "OfficeHours",
                    "Timezone",
                    "Month",
                    "Day",
                    "DateSep",
                    "ProCalendarWeeks",
                    "TaskHeaderCount",
                ],
                this.prefType,
            ),
        }).then((resp) => {
            this.weekStartDay = this.initWeekStartDay = parseInt(
                PreferenceService.extractWeekStart(resp.prefs.CalendarView.value),
            );
            this.displayStart = this.finalDisplayStart = PreferenceService.extractDisplayStart(
                resp.prefs.OfficeHours.value,
            );
            this.displayEnd = this.finalDisplayEnd = PreferenceService.extractDisplayEnd(resp.prefs.OfficeHours.value);
            this.dateOrder = this.initDateOrder = resp.prefs.DateOrder.value;
            this.timeDisplay = this.initTimeDisplay = resp.prefs.TimeDisplay.value;
            this.timezone = this.initTimezone = parseInt(resp.prefs.Timezone.value);
            this.dateSep = resp.prefs.DateSep.value;
            this.month = resp.prefs.Month.value;
            this.day = resp.prefs.Day.value;
            this.dateTimeFormat = this.selectedDateFormat();
            this.initOfficeHours = PreferenceService.formOfficeHours(this.displayStart, this.displayEnd);

            this.proCalendarWeeks = this.initProCalendarWeeks = parseInt(resp.prefs.ProCalendarWeeks.value);

            const taskValue = resp.prefs.TaskHeaderCount?.value || "Outstanding";

            this.task.selected = {
                property: "val",
                val: taskValue.startsWith("query") ? "&" + taskValue : taskValue,
                itemTypeId: Ids.Task,
            };

            this.init = true;
            this.cd.detectChanges();
        });
    }

    async validateTaskCount(event: DropDownItem) {
        this.task.changed = true;
        const queryId = (event.val as string).match(/\d+/);
        if (queryId && queryId[0]) {
            const searchModel = await SearchService.getFullSearchCriteria(Ids.Task, parseInt(queryId[0]));
            const dateBean = searchModel?.model?.step[0]?.dateBean[0].step_param[0];
            if (
                (typeof dateBean.from_dt === "number" && dateBean.from_dt > 0) ||
                (typeof dateBean.until_dt === "number" && dateBean.until_dt > 0)
            ) {
                AlertService.alert("Date range is set to a static date. This will not work for header count.");
            }
        }
    }

    selectedDateFormat() {
        this.dateTimeFormat = S25Dateformat.getDateTimeFormat({
            weekstart: this.weekStartDay,
            timedisplay: this.timeDisplay,
            datesep: this.dateSep,
            month: this.month,
            day: this.day,
            dateorder: this.dateOrder,
        });
        return S25Datefilter.transform(this.today, this.dateTimeFormat);
    }

    getDateFormat() {
        let dateFormat = S25Dateformat.getDateformat({
            dateorder: this.dateOrder,
            datesep: this.dateSep,
            day: this.day,
            month: this.day,
        });
        return dateFormat;
    }

    /// testing datepicker get selected date
    onChangeDate(e: any, occDate?: any) {
        if (occDate) {
            let selectedDate: any = S25Datefilter.transform(e.setDate(e.getDate()), this.getDateFormat());
            let checkDate: any = this.initTestDates.occurrencesDates.indexOf(selectedDate);

            if (checkDate === -1) {
                this.initTestDates.occurrencesDates.push(selectedDate);
            } else {
                this.initTestDates.occurrencesDates = this.initTestDates.occurrencesDates.filter(
                    (item: any) => item != selectedDate,
                );
            }
            this.testDates = this.initTestDates;
            // DatepickerApi.refresh(this.elementRef.nativeElement);
        }
    }
    //END testing datepicker get selected date

    getCurrentUserName() {
        return ContactService.getCurrentUsername().then((username) => {
            this.username = username;
        });
    }

    onChange($event: any) {
        this.selectedDateFormat();
        this.dateTimeFormat = this.selectedDateFormat();
        this.cd.detectChanges();
    }

    onChangeTime = (e: any, name: any) => {
        name === "displayStart" ? (this.finalDisplayStart = e) : (this.finalDisplayEnd = e);
        S25Util.date.toS25ISOTimeStr(this.finalDisplayEnd || this.displayEnd) >
        S25Util.date.toS25ISOTimeStr(this.finalDisplayStart || this.displayStart)
            ? (this.endTimeError = false)
            : (this.endTimeError = true);
        this.officeHours = PreferenceService.formOfficeHours(
            this.finalDisplayStart || this.displayStart,
            this.finalDisplayEnd || this.displayEnd,
        );
        this.displayStart = PreferenceService.extractDisplayStart(this.officeHours);
        this.cd.detectChanges();
    };

    selectAllHours() {
        this.finalDisplayStart = this.displayStart = PreferenceService.extractDisplayStart(
            "0000000000000000\\;0000000023590000\\;1!;2!;3!;4!;5!;6!;7",
        );
        this.finalDisplayEnd = this.displayEnd = PreferenceService.extractDisplayEnd(
            "0000000000000000\\;0000000023590000\\;1!;2!;3!;4!;5!;6!;7",
        );
        this.officeHours = PreferenceService.formOfficeHours(this.displayStart, this.displayEnd);
        TimepickerApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();
        let promiseArr = [];
        if (!this.endTimeError) {
            this.prefType === "U" &&
                this.initProCalendarWeeks != this.proCalendarWeeks &&
                promiseArr.push(
                    PreferenceService.setPreference("ProCalendarWeeks", this.proCalendarWeeks, this.prefType),
                );
            this.initDateOrder !== this.dateOrder &&
                promiseArr.push(PreferenceService.setPreference("DateOrder", this.dateOrder, this.prefType));
            this.initTimeDisplay !== this.timeDisplay &&
                promiseArr.push(PreferenceService.setPreference("TimeDisplay", this.timeDisplay, this.prefType));
            parseInt(this.initWeekStartDay) !== parseInt(this.weekStartDay) &&
                promiseArr.push(PreferenceService.setPreference("CalendarView", this.weekStartDay, this.prefType));
            this.initTimezone !== this.timezone &&
                promiseArr.push(PreferenceService.setPreference("Timezone", this.timezone, this.prefType));
            this.officeHours &&
                this.initOfficeHours !== this.officeHours &&
                promiseArr.push(PreferenceService.setPreference("OfficeHours", this.officeHours, this.prefType));
            this.task.changed && promiseArr.push(TaskService.setHeaderCountPref(this.task.selected?.val as string));
            if (promiseArr) {
                return S25Util.all(promiseArr).then((resp) => {
                    this.loading = false;
                    this.prefType === "U" ? (this.reload = true) : "";
                    this.cd.detectChanges();
                });
            }
        } //END IF endTimeError
    }

    cancel() {
        this.getPrefs().then((resp) => {
            parseInt(this.timeDisplay) === 24 ? (this.timeFormat = "S25|H:mm") : (this.timeFormat = "S25|h:mm a");
            TimepickerApi.init(this.elementRef.nativeElement);
            this.cd.detectChanges();
        });
    }

    windowReload() {
        window.location.reload();
    }
}
