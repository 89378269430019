import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { S25MultiDatepickerComponent } from "./s25.multi.datepicker.component";
import { TimeModelI } from "../../pojo/TimeModelI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Event } from "../../pojo/Event";
import { Bind } from "../../decorators/bind.decorator";
import Occurrence = Event.Occurrence;

@TypeManagerDecorator("s25-ng-occurrence-datepicker")
@Component({
    selector: "s25-ng-occurrence-datepicker",
    template: `
        <s25-ng-multi-datepicker
            [onSelect]="onDateSelect"
            [selectedDates]="selectedDates"
            [firstDate]="firstDate"
            [includeFirstDate]="includeFirstDate"
            [apiBean]="apiBean"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [validateDate]="validateDate"
            (apiBeanChange)="onApiBeanChange($event)"
            [inputLabel]="inputLabel"
            [inputId]="inputId"
            [prefType]="prefType"
            [onClose]="onClose"
            (datesChange)="datesChange.emit($event)"
        ></s25-ng-multi-datepicker>
    `,
})
export class S25OccurrenceDatepickerComponent extends S25MultiDatepickerComponent implements OnInit, OnChanges {
    @Input() timeModel: TimeModelI;
    @Input() occurrences: Occurrence[];

    @Output() occurrencesChange = new EventEmitter<Occurrence[]>();

    @ViewChild(S25MultiDatepickerComponent) datePicker: S25MultiDatepickerComponent;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.occurrences && !changes.timeModel) this.refreshOccurrences(); // onTimeModelChange already refreshes
        if (changes.timeModel) this.onTimeModelChange();
    }

    ngOnInit() {
        super.ngOnInit();
        this.apiBean.getOccurrences = () => this.occurrences;
    }

    @Bind
    onDateSelect(data: { date: Date; isSelected: boolean }) {
        if (!data.isSelected) this.addOccurrence(data.date);
        else this.removeOccurrence(data.date);
        this.occurrencesChange.emit(this.occurrences);
    }

    addOccurrence(date: Date) {
        let start = S25Util.date.clone(date);
        let end = S25Util.date.clone(date);

        start.setHours(this.timeModel.evStartDt.getHours());
        start.setMinutes(this.timeModel.evStartDt.getMinutes());

        end.setHours(this.timeModel.evEndDt.getHours());
        end.setMinutes(this.timeModel.evEndDt.getMinutes());
        end = S25Util.date.addDays(end, S25Util.date.diffDays(this.timeModel.evStartDt, this.timeModel.evEndDt));

        this.occurrences.push({ evStartDt: start, evEndDt: end });

        // Refresh calendar
        this.refreshOccurrences();
    }

    // Find and remove corresponding occurrence
    removeOccurrence(date: Date) {
        for (let [i, occ] of Object.entries(this.occurrences)) {
            if (S25Util.date.toStartOfDay(occ.evStartDt) <= date && date <= S25Util.date.toEndOfDay(occ.evEndDt)) {
                this.occurrences.splice(Number(i), 1);
                break;
            }
        }

        // Refresh calendar
        this.refreshOccurrences();
    }

    refreshOccurrences() {
        const dates: Date[] = [];
        for (let occ of this.occurrences) {
            dates.push(S25Util.date.toStartOfDay(occ.evStartDt));
            const diff = S25Util.date.diffDays(occ.evStartDt, occ.evEndDt);
            for (let i = 1; i <= diff; i++) {
                dates.push(S25Util.date.toStartOfDay(S25Util.date.addDays(occ.evStartDt, i)));
            }
        }

        this.selectedDates = dates;
        if (this.datePicker) {
            this.datePicker.selectedDates = dates;
            this.datePicker.selectedDateHash = [];
            for (let date of dates) this.datePicker.selectedDateHash[date.getTime()] = date;
            this.datePicker.refreshSelected();
        }
    }

    onTimeModelChange() {
        // On time model change, update all occurrences to fit new model
        const diff = S25Util.date.diffDays(this.timeModel.evStartDt, this.timeModel.evEndDt);
        for (let occ of this.occurrences) {
            occ.evEndDt = S25Util.date.copyTime(S25Util.date.addDays(occ.evStartDt, diff), this.timeModel.evEndDt);
        }
        this.refreshOccurrences();
    }

    onApiBeanChange(api: any) {
        api.getOccurrences = () => this.occurrences;
        this.apiBeanChange.emit(api);
    }
}
