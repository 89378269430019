import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";

@TypeManagerDecorator("s25-datepicker-inline")
@Component({
    selector: "s25-datepicker-inline",
    template: `
        @if (this.init) {
            <s25-datepicker [modelValue]="dateModel" (modelValueChange)="onDateModelChange($event)"></s25-datepicker>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DatepickerInlineComponent implements OnInit {
    dateModel = { date: S25Util.date.currentDate() };
    init = false;

    @Input() date: Date;
    @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.dateModel.date = this.date || this.dateModel.date;
        this.init = true;
        this.cd.detectChanges();
    }

    onDateModelChange = ($event: any) => {
        this.dateModel.date = $event;
        this.dateChange.emit(this.dateModel.date);
        this.cd.detectChanges();
    };
}
